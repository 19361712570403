@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  font-family: "Poppins", "Rubik", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  -webkit-tap-highlight-color: transparent;
  overscroll-behavior-y: none;
  background-color: #f5f5f5;
}